
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, toRefs } from "vue";
import CreateInvoice from   "@/views/management/modals/EditInvoice.vue"
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import * as Mustache from 'mustache';
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";

export class Invoice{
    id : number;
    invoice_date : Date;
    student_id : number;
    memo : string;
}

export default defineComponent({
    name: "invoice",
    components : {
        CreateInvoice
    },
    setup() {
        const route = useRoute();
        const selectedInvoiceId = ref(0);
        const students : any[] = reactive([]);
        const pageSize = 15;
        const invoiceTemplateId = 1;
        let invoiceTemplate = '';
        const page = reactive({pageValue : 1});
        const invoices : any[] = reactive([]);
        const selectedInvoiceIds = ref([]);

        const updateInvoiceId = (id) =>{
            selectedInvoiceId.value = id;
        }

        const getReport = async() =>{
            ApiService.setHeader();
            await ApiService.get("report/"+invoiceTemplateId)
                    .then(({ data }) => {
                        invoiceTemplate = data.data.report_content;
                    })
                    .catch(({ response }) => {
                    console.log('getReport error => '+response.data.errors);
                    });
        }

        const getInvoices = async () => {
            await new Promise((resolve, reject) => {      
                ApiService.setHeader();      
                ApiService.get("invoice")
                    .then(({ data }) => {
                        Object.assign(invoices,data.data);
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const resetSelectedInvoiceId= () =>{
            selectedInvoiceId.value = 0;
        }

        const getInvoice = (id) : any => {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("invoice/"+id)
                    .then(({ data }) => {
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getInvoice error => '+response.data.errors));
                        console.log('getInvoice error => '+response.data.errors);
                    });
            });
        }

        const handleDelete = (row) => {
            ElMessageBox.confirm('Are you sure to delete this record?')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("invoice/"+row.id)
                    .then(() => {
                        let index = invoices.findIndex(d => d.id === row.id);
                        invoices.splice(index,1);   
                    })
                    .catch(({ response }) => {
                        console.log('handleDelete error => '+response.data.errors);
                    });
            });
        };

        const search = reactive({searchValue : ''});

        const pagedInvoices = computed(()=>{
            return invoices
                .filter(data => (!search 
                            || data.student?.first_name.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.student?.last_name.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.student?.student_id.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase()))
                )
                .sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.record_date!) < Date.parse(b.record_date!))
                        return 1;
                    if (Date.parse(a.record_date!) > Date.parse(b.record_date!))
                        return -1;
                    return 0;
                })
                .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue)
        })

        const setPage = (val) => {
            page.pageValue = val;
        }

        const handleSelectionChange = (rows) =>{
            selectedInvoiceIds.value = rows.map(x=>x.id);
        }

        const printInvoiceAll = async () => {
            if(!selectedInvoiceIds.value || selectedInvoiceIds.value.length == 0){
                Swal.fire({
                        text: "Please select one or more invoices",
                        icon: "warning",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        })
            }
            else{
                var printInvoiceResult = '';

                var printAll = new Promise<void>((resolve, reject) => {
                    selectedInvoiceIds.value.forEach((value, index, array) => {
                        getInvoicePrintData(value).then((printedInvoice: any) =>{
                            printInvoiceResult += printedInvoice;
                            printInvoiceResult += '<div style="break-after:page"></div>';
                            if (index === array.length -1) resolve();
                        });
                    });
                })                

                printAll.then(() =>{
                    let invoiceWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes");
                    if(invoiceWindow){
                        invoiceWindow.document.body.innerHTML = printInvoiceResult;
                    }
                });
            }
        }

        const printInvoice = async (row) => {
            getInvoicePrintData(row.id).then((printedInvoice: any) =>{
                let invoiceWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
                if(invoiceWindow){
                    invoiceWindow.document.body.innerHTML = printedInvoice;
                };
            });
        }

        const getInvoicePrintData = async(id) =>{
            return new Promise((resolve, reject) => { 
                getInvoice(id).then((data) => {
                    data.invoice_records = data.invoice_records.sort(function(a,b) { 
                        if(a.finance_record.id == b.finance_record.id) return 0;

                        if (Date.parse(a.finance_record.record_date!) > Date.parse(b.finance_record.record_date!))
                            return 1;
                        if (Date.parse(a.finance_record.record_date!) < Date.parse(b.finance_record.record_date!))
                            return -1;
                        return 0;
                    });
                    let totalChargeAmount = 0;
                    let totalPaidAmount = 0;

                    data.invoice_date = moment(data.invoice_date).format('MM/DD/YYYY');
                    data.invoice_records.forEach(element => {
                        element.record_date = moment(element.finance_record.record_date).format('MM/DD/YYYY');
                        element.ref_no = String(element.finance_record.id).padStart(8, "0");
                        element.memo = element.finance_record.memo;
                        element.payment_method = element.finance_record.payment_method;
                        if(element.finance_record.record_type == 'Receivable'){
                            element.charge_amount = element.finance_record.amount.toLocaleString("en-US", {style: "currency", currency: "USD"});
                            totalChargeAmount += +element.finance_record.amount;
                        }
                        else if(element.finance_record.record_type == 'Deposit' || element.finance_record.record_type == 'Credit' ) {
                            if(element.finance_record.record_type == 'Deposit'){
                                element.paid_amount = element.finance_record.amount.toLocaleString("en-US", {style: "currency", currency: "USD"});
                            }
                            if(element.finance_record.record_type == 'Credit'){
                                element.credit = element.finance_record.amount.toLocaleString("en-US", {style: "currency", currency: "USD"});
                            }
                            totalPaidAmount += +element.finance_record.amount;
                        }
                        element.balance = (totalChargeAmount - totalPaidAmount).toLocaleString("en-US", {style: "currency", currency: "USD"});
                    });

                    data.total_amount = (totalChargeAmount - totalPaidAmount).toLocaleString("en-US", {style: "currency", currency: "USD"});
                    var invoicePrintData = Mustache.render(invoiceTemplate, data);
                    resolve(invoicePrintData);
                })
                .catch((error) => {
                    reject(new Error ('getInvoice error => '+error));
                });
            });
        }

        const getStudents = async() =>{ 
            await new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get("student/status/Active")
                .then(({ data }) => {                    
                    students.values = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log('getStudents error => '+response.data.errors);
                    reject('getStudents error => '+response.data.errors);
                });
            });
        }

        const editInvoice = (row, data) =>{
            if(data.property !== 'handlers'){
                selectedInvoiceId.value = row.id;
                let invoiceButton = document.getElementById('invoice_create_button');
                invoiceButton?.click();
            }
        }

        onBeforeMount(async () => {
            getStudents();
            getInvoices();
            getReport();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Invoice", ["Management"]);
            if(route.params.param==='create'){
                editInvoice({id:0}, {property : ''});
            }
        });

        return {
            handleDelete,
            printInvoice,
            setPage,
            pagedInvoices,
            pageSize,
            page,
            invoices,
            search,
            moment,
            selectedInvoiceId,
            students,
            invoiceTemplateId,
            editInvoice,
            resetSelectedInvoiceId,
            getInvoices,
            updateInvoiceId,
            handleSelectionChange,
            printInvoiceAll
        };
    },
});
